import './bootstrap';
import 'flowbite';
import {createWidget} from '@typeform/embed'
import lity from 'lity';
import Datepicker from 'flowbite-datepicker/Datepicker';
import {Tooltip} from "flowbite";
import * as Sentry from "@sentry/browser";

const env = import.meta.env.VITE_APP_ENV;
if (['production', 'staging'].includes(env)) {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        environment: import.meta.env.VITE_APP_ENV,
    });
}

window.createWidget = createWidget;
window.lity = lity;
window.Datepicker = Datepicker;
window.Tooltip = Tooltip;

//@TODO hacky function until we upgrade to flowbite v2 and have access to https://flowbite.com/docs/getting-started/javascript/
window.showTooltip = (targetElId, triggerElId) => {
    const targetEl = document.getElementById(targetElId);
    const triggerEl = document.getElementById(triggerElId);
    const tooltip = new Tooltip(targetEl, triggerEl, 'hover')
}

window.slugify = function slugify(str) {
    return String(str)
            .normalize('NFKD') // split accented characters into their base characters and diacritical marks
            .replace(/[\u0300-\u036f]/g, '') // remove all the accents, which happen to be all in the \u03xx UNICODE block.
            .trim() // trim leading or trailing whitespace
            .toLowerCase() // convert to lowercase
            .replace(/[^a-z0-9 -]/g, '') // remove non-alphanumeric characters
            .replace(/\s+/g, '-') // replace spaces with hyphens
            .replace(/-+/g, '-'); // remove consecutive hyphens
}
